        /**
         * parcelRequire reserverd variable by parcel bundler
         */
        var parcelRequire;

        
        import GSAP from "gsap";
        import { CSSRulePlugin } from "gsap/CSSRulePlugin";
        import AOS from 'aos';

        import '../../node_modules/popper.js/dist/popper.min.js'
        import '../../node_modules/bootstrap/dist/js/bootstrap.min.js'


        GSAP.registerPlugin(CSSRulePlugin);

        import hexagon_black from '../../media/hexagon-black.png';
        import hexagon_white from '../../media/hexagon-vector.png';
        import defautLogo from '../../media/Faysal-Exchange-Logo.png';
        import LogoWhite from '../../media/logo-white.png';

        import pattern  from '../../media/pattern.png'

        import sectionSixWhite from '../../media/section-6-bg.png'
        import sectionSevenDark from '../../media/section-7-dark.png'

        import locationMapIcons from '../../media/location.png';
        window.$ = window.jQuery = require("jquery");
        require('select2')();

        import Swiper from "swiper";
        import { Pagination, Autoplay,Scrollbar } from 'swiper/modules';

        class App {

            constructor(){
     
                this.createSwitcher()

                if (JSON.parse(localStorage.getItem('dark_mode'))) {
                    this.onClickSwitch('onPageLoad')
                }

                this.createHamburger()
                this.createResumeInput()
                this.createAOS()
                this.createApiData()
                this.createOffCanvas()
                this.createAnimateLinks()
                this.onOffCanvasEvents()
                this.createAccordionRotate()
                this.addEventsListeners()

                this.isTrue = false

            }

            createHamburger(){
                this.humberger = document.querySelector('.effect1')

            }

            onHamburgerClick(){
                this.humbergerLines = document.querySelector('.effect1 span')
                this.humbergerLines.classList.toggle('active')
            }
         

            createResumeInput(){
                this.resumeFileInput = document.querySelector('#career-resume-file')
                if (this.resumeFileInput) {
                    this.resumeFileInput.setAttribute('placeholder', "Upload Resume")                    
                }
            }
            onResumeInput(){
                let that = this;
              
                that.resumeError = document.querySelector('.file__upload__error');
                that.resumeErrorText = document.querySelector('.resume__error');
                
                if (this.resumeFileInput.files.length > 0) {
                    that.fileName = this.resumeFileInput.files[0].name;
                    const mbFile = this.resumeFileInput.files[0].size;
                    const oneMb = 1048576;
                
                    if (mbFile > oneMb) {
                        that.resumeErrorText.style.display = 'block';
                        that.resumeError.style.display = 'none';
                        return; 
                    } else {
                        that.resumeErrorText.style.display = 'none';
                    }
                
                    that.resumeError.style.display = 'none';
                    this.resumeFileInput.setAttribute('placeholder', that.fileName);
                } else {                 
                    this.resumeFileInput.setAttribute('placeholder', 'Upload Resume');
                    that.resumeError.style.display = 'block'; 
                }
                
            }

            createAOS(){
                AOS.init()
            }
            
            createOffCanvas(){
                this.offCanvasEnable = document.querySelector('.offcanvas__nav')                
                this.offCanvasDisable = document.querySelector('.offcanvas__close__icon')
                this.offcanvas = document.querySelector('.offcanvas__wrapper')
                if (this.offcanvas) {
                    GSAP.set(this.offcanvas,{display:'none'})
                }
            }

            onOffCanvasEnable(){

                this.onTl =  GSAP.timeline({paused:true})

                let offCanvas = document.querySelector('.offcanvas__container')
                let offCanvasWraper = document.querySelector('.offcanvas__wrapper')
                this.onTl.to(offCanvas,{height:300,duration:1,ease:'Power0.inOut'}) 
                this.onTl.to(offCanvasWraper,{display:'block',duration:0.2,ease:'Power0.inOut',opacity:1})
                this.isTrue = true  

                return this.onTl;
            }

            onOffCanvasDisable(){
                this.ofTl  = GSAP.timeline({paused:true})

                let offCanvas = document.querySelector('.offcanvas__container')
                let offCanvasWraper = document.querySelector('.offcanvas__wrapper')
                this.ofTl.to(offCanvasWraper,{display:'none',duration:0.2,opacity:0,ease:'Power0.inOut'})             
                this.ofTl.to(offCanvas,{height:0,duration:0.5,ease:'Power0.inOut'}) 

                return this.ofTl;
            }

            createAccordionRotate(){

                let that = this

                that.accordions = [...document.querySelectorAll('.card-header')]

                if (that.accordions) {
                    if (that.accordions[0]) {
                        that.accordions[0].querySelector('svg').style.transform = "rotate(180deg)"           
                    }
                    that.accordions.forEach((element) => {
                        element.addEventListener('click', function () {
                            let svg = element.querySelector('svg');            
                            const isExpanded = element.nextElementSibling.classList.contains('show')
                            if (isExpanded) {
                                svg.style.transform = "rotate(0deg)"
                            } else {
                                svg.style.transform = "rotate(180deg)"
                            }            
                            svg.style.transition = 'transform 0.5s ease'            
                            that.accordions.forEach((otherElement) => {
                                if (otherElement !== element) {
                                    let otherSvg = otherElement.querySelector('svg')
                                    otherSvg.style.transform = "rotate(0deg)"
                                }
                            })
                        })
                    })
                }                                
            }


            createApiData() {

                let that = this;

                this.onContactSubmitForm =  document.querySelector('#contact__form')
                this.onCareerSubmitForm =  document.querySelector('#career__form')

                that.contains = document.querySelector('.additional__section')
                that.containsBranches = document.querySelector('.section-3-right .branches__wrapper')
                that.buyExchangePkr = document.querySelector('.buy_pkr')
                that.buyExchangeAed = document.querySelector('.buy_aed')
                that.sellExchangePkr = document.querySelector('.sell_pkr')
                that.sellExchangeAed = document.querySelector('.sell_aed')
                // that.branchCity = document.querySelector('#branch-city')
                // that.branchArea  = document.querySelector('#branch-area')
                that.currencySlider = document.querySelector('.currency-slider .swiper-wrapper')
                        
                fetch('https://faysalexchange.digitzmedia.com/dashboard/public/api/branches/all').then((response) => {

                    return response.json()

                }).then((afterResponse) => {

                    for (const key in Object.keys(afterResponse)) {

                        if (key >= 3 ) break;
                        // if (that.branchCity) {
                        //     that.branchCity.innerHTML += `<option>${afterResponse[key].city.name}</option>`
                        // }
                        // if (that.branchArea) {
                        //     that.branchArea.innerHTML += `<option>${afterResponse[key].area.name}</option>`
                        // }
                        if (that.containsBranches) {
                            that.containsBranches.innerHTML +=
                            `
                            <div class="branch d-flex flex-column justify-content-around py-4" style="gap:15px">
                                <div class="branch__title d-flex flex-row align-items-center ml-4" style="gap: 15px;">
                                    <img class="img-fluid" src="${locationMapIcons}" alt="">
                                    <h5 class="m-0 para__text white__text">${afterResponse[key].name}</h5>
                                </div>
                                <p class="ml-4 my-0 blue__text">${afterResponse[key].address}</p>
                                <h5 class="ml-4 para__text blue__text">Contact :&nbsp;<span class="white__text"><a class="location__contact__numbers blend__mode__white" href="tel:${afterResponse[key].contact}">${afterResponse[key].contact}</a></span></h5>
                            </div>

                            `;
                        }
                    }

                })  

                fetch('https://faysalexchange.digitzmedia.com/dashboard/public/api/currency/rates').then((response) => {
                        return response.json()
                    }).then((afterResponse) => {
                        for (const currency of afterResponse) {
                            if (that.contains) {                        
                                that.contains.innerHTML +=
                                    `<div class='exchange__rate__column row py-xxl-3 py-xl-3 py-lg-3 py-md-3 py-sm-2 py-2'>
                                        <div class='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 d-flex align-items-center justify-content-center'>
                                            <h5 class="para__text"><img  src="${currency.currency.flag}" class="img-fluid"></h5>
                                            <h5 class="para__text ml-2 white__text">${currency.currency.code}</h5>
                                        </div>
                                        <div class='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 d-flex align-items-center justify-content-center'><h5 class="blue__text">${currency.buy}</h5></div>
                                        <div class='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 d-flex align-items-center justify-content-center'><h5 class="blue__text">${currency.sell}</h5></div>
                                    </div>
                                `;
                            }
                            if (that.currencySlider) {
                                that.currencySlider.innerHTML +=`<div class="swiper-slide"><div class="d-flex justify-content-start align-items-center"><img class="img-fluid mr-1" src="${currency.currency.flag}" alt=""><span> &nbsp;${currency.currency.code }&nbsp;&nbsp;${ currency.buy} | ${ currency.sell}</span></div></div>`
                            }                    
                            if (that.buyExchangePkr) {
                                that.buyExchangePkr.innerHTML +=`<option data-icon="${currency.currency.flag}"  value="${currency.buy}">${currency.currency.code}</option>`;
                            }
                            if (that.buyExchangeAed) {
                                that.buyExchangeAed.innerHTML +=`<option data-icon="${currency.currency.flag}"  value="${currency.buy}">${currency.currency.code}</option>`;
                            }
                            if (that.sellExchangePkr) {
                                that.sellExchangePkr.innerHTML +=`<option data-icon="${currency.currency.flag}" value="${currency.sell}">${currency.currency.code}</option>`;
                            }
                            if (that.sellExchangeAed) {
                                that.sellExchangeAed.innerHTML +=`<option data-icon="${currency.currency.flag}" value="${currency.sell}">${currency.currency.code}</option>`;
                            }

                    }
                    
                    $(function() {
                        
                        /**
                        * buy calculator field
                        */
                        let that = this;

                        that.CurrencyEventsFrom = function() {
                            that.inputValue = document.querySelector('#from-amount').value.replace(/,/g, ''); 
                            that.from_currency = document.querySelector('#from-currency').value; 
                            that.to_currency = document.querySelector('#to-currency').value;
                        
                            if (!isNaN(that.inputValue) && !isNaN(that.from_currency) && !isNaN(that.to_currency)) {
                                that.result = (Number(that.inputValue) * Number(that.from_currency)) / Number(that.to_currency);
                                document.querySelector('#to-amount').value = Number(that.result.toFixed(2)).toLocaleString();
                            } else {
                                document.querySelector('#to-amount').value = '';
                            }                        
                            document.querySelector('#from-amount').value = isNaN(Number(that.inputValue)) ? '' : Number(that.inputValue).toLocaleString();
                        };
                        
                        that.CurrencyEventsTo = function() {
                            that.inputValue = document.querySelector('#to-amount').value.replace(/,/g, '');
                            that.to_currency = document.querySelector('#to-currency').value; 
                            that.from_currency = document.querySelector('#from-currency').value;
                        
                            if (!isNaN(that.inputValue) && !isNaN(that.to_currency) && !isNaN(that.from_currency)) {
                                that.result = (Number(that.inputValue) * Number(that.to_currency)) / Number(that.from_currency);
                                document.querySelector('#from-amount').value = Number(that.result.toFixed(2)).toLocaleString();
                            } else {
                                document.querySelector('#from-amount').value = '';
                            }                        
                            document.querySelector('#to-amount').value = isNaN(Number(that.inputValue)) ? '' : Number(that.inputValue).toLocaleString();                                                  
                        };

                        that.ChangeEventOfFromAmount = function(event) {
                            that.inputValue = event.target.value.replace(/,/g, '');
                            that.from_currency = document.querySelector('#from-currency').value; 
                            that.to_currency = document.querySelector('#to-currency').value;
                        
                            if (!isNaN(that.inputValue) && that.inputValue > 0 && !isNaN(that.from_currency) && !isNaN(that.to_currency)) {
                                that.result = (Number(that.inputValue) * Number(that.from_currency)) / Number(that.to_currency);
                                document.querySelector('#to-amount').value = Number(that.result.toFixed(2)).toLocaleString();
                            } else {
                                document.querySelector('#to-amount').value = '';
                            }                       
                            event.target.value = isNaN(Number(that.inputValue)) ? '' : Number(that.inputValue).toLocaleString();
               
                        };

                        that.ChangeEventOfToAmount = function(event) {
                            that.inputValue = event.target.value.replace(/,/g, '');
                            that.to_currency = document.querySelector('#to-currency').value; 
                            that.from_currency = document.querySelector('#from-currency').value;
                        
                            if (!isNaN(that.inputValue) && that.inputValue > 0 && !isNaN(that.to_currency) && !isNaN(that.from_currency)) {
                                that.result = (Number(that.inputValue) * Number(that.to_currency)) / Number(that.from_currency);
                                document.querySelector('#from-amount').value = Number(that.result.toFixed(2)).toLocaleString();
                            } else {
                                document.querySelector('#from-amount').value = '';
                            }                
                            event.target.value = isNaN(Number(that.inputValue)) ? '' : Number(that.inputValue).toLocaleString();
                                                   
                        };                                
                        that.formatOptionBuy = function(option) {
                            if (!option.id) return option.text; 
                            that.imgSrc = $(option.element).data('icon');
                            that.options = $(`
                                <span class="d-flex align-items-center">
                                    <img class="mr-2 img-fluid currency__img__icons" src="${that.imgSrc}"/>${option.text}
                                </span>
                            `);
                            return that.options;                           
                        }
                        that.selectBuy = [...document.querySelectorAll('.tab__1 .select')];                
                        that.selectBuy.forEach(function(element) {
                            $(element).select2({
                                templateResult: that.formatOptionBuy,
                                templateSelection: that.formatOptionBuy                                              
                            }).on('change', function() {
                                if (element.id === 'to-currency') {
                                    that.CurrencyEventsFrom()                                  
                                } else {
                                    that.CurrencyEventsTo()
                                }
                            })
                        })                
                        that.inputBuyFrom = document.querySelector('#from-amount')
                        that.inputBuyTo = document.querySelector('#to-amount')

                        if (!that.inputBuyFrom) return
                        if (!that.inputBuyTo) return

                        that.inputBuyFrom.addEventListener('input', that.ChangeEventOfFromAmount.bind(that));
                        that.inputBuyTo.addEventListener('input', that.ChangeEventOfToAmount.bind(that));
                    
                        /**
                         * sell calculator field
                         */
                        that.SellCurrencyEventsFrom = function() {
                            that.inputValue = document.querySelector('#sell-from-amount').value.replace(/,/g, '');
                            that.from_currency = document.querySelector('#sell-from-currency').value;
                            that.to_currency = document.querySelector('#sell-to-currency').value;
                        
                            if (!isNaN(that.inputValue) && !isNaN(that.from_currency) && !isNaN(that.to_currency)) {
                                that.result = (Number(that.inputValue) * Number(that.from_currency)) / Number(that.to_currency);
                                document.querySelector('#sell-to-amount').value = Number(that.result.toFixed(2)).toLocaleString();
                            } else {
                                document.querySelector('#sell-to-amount').value = '';
                            }                        
                            document.querySelector('#sell-from-amount').value = isNaN(Number(that.inputValue)) ? '' : Number(that.inputValue).toLocaleString();
                        };                        
                        that.SellCurrencyEventsTo = function() {
                            that.inputValue = document.querySelector('#sell-to-amount').value.replace(/,/g, '');
                            that.to_currency = document.querySelector('#sell-to-currency').value;
                            that.from_currency = document.querySelector('#sell-from-currency').value;
                        
                            if (!isNaN(that.inputValue) && !isNaN(that.to_currency) && !isNaN(that.from_currency)) {
                                that.result = (Number(that.inputValue) * Number(that.to_currency)) / Number(that.from_currency);
                                document.querySelector('#sell-from-amount').value = Number(that.result.toFixed(2)).toLocaleString();
                            } else {
                                document.querySelector('#sell-from-amount').value = '';
                            }                        
                            document.querySelector('#sell-to-amount').value = isNaN(Number(that.inputValue)) ? '' : Number(that.inputValue).toLocaleString();
                           
                        }                        
                        that.SellChangeEventOfFromAmount = function(event) {
                            that.inputValue = event.target.value.replace(/,/g, '');
                            that.from_currency = document.querySelector('#sell-from-currency').value;
                            that.to_currency = document.querySelector('#sell-to-currency').value;
                        
                            if (!isNaN(that.inputValue) && that.inputValue > 0 && !isNaN(that.from_currency) && !isNaN(that.to_currency)) {
                                that.result = (Number(that.inputValue) * Number(that.from_currency)) / Number(that.to_currency);
                                document.querySelector('#sell-to-amount').value = Number(that.result.toFixed(2)).toLocaleString();
                            } else {
                                document.querySelector('#sell-to-amount').value = '';
                            }                
                            event.target.value = isNaN(Number(that.inputValue)) ? '' : Number(that.inputValue).toLocaleString();
                        }                
                        that.SellChangeEventOfToAmount = function(event) {x
                            that.inputValue = event.target.value.replace(/,/g, '');
                            that.to_currency = document.querySelector('#sell-to-currency').value;
                            that.from_currency = document.querySelector('#sell-from-currency').value;
                        
                            if (!isNaN(that.inputValue) && that.inputValue > 0 && !isNaN(that.to_currency) && !isNaN(that.from_currency)) {
                                that.result = (Number(that.inputValue) * Number(that.to_currency)) / Number(that.from_currency);
                                document.querySelector('#sell-from-amount').value = Number(that.result.toFixed(2)).toLocaleString();
                                   
                            } else {
                                document.querySelector('#sell-from-amount').value = '';
                            }                        
                            event.target.value = isNaN(Number(that.inputValue)) ? '' : Number(that.inputValue).toLocaleString();
                        }                            
                        that.formatOptionSell = function(option) {
                            if (!option.id) return option.text; 
                            that.imgSrc = $(option.element).data('icon');
                            that.options = $(`
                                <span class="d-flex align-items-center">
                                    <img src="${that.imgSrc}" class="mr-2" style="width: 20px; height: 20px;"/>${option.text}
                                </span>
                            `);
                            return that.options;
                        }
                        that.selectSell = [...document.querySelectorAll('.tab__2 .select')];
                        that.selectSell.forEach(function(element) {
                            $(element).select2({
                                templateResult: that.formatOptionSell,
                                templateSelection: that.formatOptionSell                                              
                            }).on('change', function() {
                                if (element.id === 'sell-to-currency') {
                                    that.SellCurrencyEventsFrom()
                                } else {
                                    that.SellCurrencyEventsTo()
                                }
                            })
                        })

                        that.inputSellFrom = document.querySelector('#sell-from-amount')
                        that.inputSellTo = document.querySelector('#sell-to-amount')

                        if(!that.inputSellFrom)return
                        if(!that.inputSellTo)return

                        that.inputSellFrom.addEventListener('input',that.SellChangeEventOfFromAmount.bind(that));
                        that.inputSellTo.addEventListener('input', that.SellChangeEventOfToAmount.bind(that));
                    })           
                    this.containsBG = [...document.querySelectorAll('.exchange__rate__column')]
                    this.containsBG.forEach((element, index) => {
                        if ((index % 2 === 0) === false) {
                            element.classList.add('exchange-coloured')
                        }else {
                            element.classList.remove('exchange-coloured')
                        }
                    })                     
                    }).then(()=>{
            
                        let that = this;

                        that.checkBoxRemove = [...document.querySelectorAll('.switch input')]
                        that.loadingImg = [...document.querySelectorAll('.loading__img')]

                        that.checkBoxRemove.forEach((element)=>{
                            element.removeAttribute('disabled','')
                        })
                        this.onClickSwitch('onPageLoad')
                        this.createSwiper()

                        // this.createSwitcher()                                    
                        // this.addEventsListeners()

                        that.selectBuy = document.querySelector('#from-currency')
                        that.selectSell =  document.querySelector('#sell-from-currency')

                        if (this.selectSell && this.selectBuy) {
                            that.optionsSell = [...document.querySelectorAll('#sell-from-currency option')]
                            that.optionsBuy = [...document.querySelectorAll('#from-currency option')]

                            that.optionsSell.find((element,i) =>{
                                if (element.innerHTML === ('pkr').toUpperCase()) {
                                    that.selectBuy.insertBefore(that.optionsBuy[i],that.optionsBuy[0])
                                    that.optionsBuy[i].setAttribute('selected','')
                                    that.selectSell.insertBefore(that.optionsSell[i],that.optionsSell[0])
                                    that.optionsSell[i].setAttribute('selected','')                             
                                }                                
                            })                          
                        }
                        if (that.loadingImg) {
                            that.loadingImg.forEach(element => element.style.display = 'none')    
                        }
                    })
                }

                async onContactSubmit(event) {
                    event.preventDefault();


                    /**
                     * validation regex 
                     */
                
                    const that = this;
        
                    that.testFullName = () => {
                        return /^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/;
                    }
                    that.testEmail = (email) =>{       
                        return  String(email).toLowerCase().match(/^(?!.*\.\.)[a-zA-Z0-9._%+-]+@(?!(?:[a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com\.)+com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/);                       
                    }
                    that.testPhone = () => {
                        return /^(\+92|92|0)?(3\d{2})\d{7}$/;
                    }
                 

                    /**
                     * inputs fields selectors 
                     */

                    that.name = document.getElementById('contact-name').value.trim();
                    that.email = document.getElementById('contact-email').value.trim();
                    that.phone = document.getElementById('contact-phone').value.trim();
                    that.contactMethod = document.querySelector('input[name="contact_me_by"]:checked')?.value || '';
                    that.questionFor = document.getElementById('contact-question-for').value
                    that.message = document.getElementById('contact-message').value.trim();
                    that.afterSendContactSucess = document.querySelector('.after__send__message__contact__success');
                    that.afterSendContactWarning = document.querySelector('.after__send__message__contact__warning');
                    that.phoneError = document.querySelector('.contact__number__error');
                    that.nameError = document.querySelector('.contact__name__error');
                    that.messageError = document.querySelector('.contact__message__error');
                    that.emailError = document.querySelector('.contact__email__error');
                    that.selectError = document.querySelector('.contact__select__error');

                    /**
                     * input functions for validations
                     */
                
                    const phoneInput = document.querySelector('#contact-phone');
                    const nameInput = document.querySelector('#contact-name');
                    const messageInput = document.querySelector('#contact-message');
                    const emailInput = document.querySelector('#contact-email');
                    const selectInput = document.querySelector('#contact-question-for');

                    selectInput.addEventListener('input', () => {
                        that.selectError.style.display = 'none';
                    });

                    emailInput.addEventListener('input', () => {
                        that.emailError.style.display = 'none';
                    });

                    phoneInput.addEventListener('input', () => {
                        that.phoneError.style.display = 'none';
                    });
                    nameInput.addEventListener('input', () => {
                        that.nameError.style.display = 'none';
                    });


                    /**
                     * validation functions
                    */
                
                    messageInput.addEventListener('input', () => {
                        that.messageError.style.display = 'none';
                    });
                
                    if (!that.name || !that.testFullName().test(that.name)) {
                        that.nameError.style.display = 'block'
                        return;
                    }
                    if (!that.email || !that.testEmail(that.email)) {
                        that.emailError.style.display = 'block'
                        return;
                    }
                    if (!that.phone || !that.testPhone().test(that.phone)) {
                        that.phoneError.style.display = 'block';
                        return;
                    }

                    if (!that.message || that.message.length < 10) {
                        that.messageError.style.display = 'block';
                        return;
                    }
                
                    grecaptcha.ready(async () => {
                        try {
                            const token = await grecaptcha.execute('6LditXgqAAAAAEKGhplTplVF2Gkadv7SQTcGVCUv', { action: 'contact' });
                            if (!token) throw new Error('Failed to obtain reCAPTCHA token.');
                
                            document.getElementById('contact-recaptcha-Response').value = token;
                
                                fetch('https://faysalexchange.digitzmedia.com/dashboard/public/api/contact', {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify({
                                        name: that.name,
                                        email: that.email,
                                        phone: that.phone,
                                        contact_me_by: that.contactMethod,
                                        question_for: that.questionFor,
                                        message: that.message,
                                        recaptchaResponse: token,
                                    }),
                                })
                                .then((response) => {
                                    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                                    console.log(response)
                                    return response.json();
                                })
                                .then((afterResponse) => {
                                    if (afterResponse.status) {
                                        that.afterSendContactSucess.style.display = 'block';
                                    } else {
                                        that.afterSendContactWarning.style.display = 'block';
                                    }
                                    event.target.reset();
                                })
                                .catch((error) => {
                                    console.error('Error:', error);
                                    that.afterSendContactWarning.style.display = 'block';
                                });
                        } catch (error) {
                            console.error('reCAPTCHA error:', error);
                            alert('Failed to verify reCAPTCHA. Please try again.');
                        }
                    });
                }
                


                /**
                 * on career submittion forms
                 */
                
            
                async onCareerSubmit(event) {
                    event.preventDefault();
        
                    const that = this;

                    /**
                     * inputs fileds selectors 
                    */

                    that.name = document.querySelector('#career-name').value;
                    that.email = document.querySelector('#career-email').value;
                    that.phone = document.querySelector('#career-phone').value;
                    // that.department_position = document.querySelector('#department-position-for').value;
                    that.careerResume = document.querySelector('#career-resume-file')
                    that.afterSendCareerSuccess = document.querySelector('.after__send__message__career__success');
                    that.afterSendCareerWarning = document.querySelector('.after__send__message__career__warning');
                    that.phoneError = document.querySelector('.careers__number__error')
                    that.resumeError = document.querySelector('.file__upload__error')
                    that.nameError = document.querySelector('.careers__name__error')
                    that.emailError = document.querySelector('.careers__email__error')

                    const phoneInput = document.querySelector('#career-phone');
                    const nameInput = document.querySelector('#career-name');
                    const emailInput = document.querySelector('#career-email');

                    /**
                    * inputs fileds functions
                    */

                    phoneInput.addEventListener('input', () => {
                        that.phoneError.style.display = 'none';                        
                    });
                
                    nameInput.addEventListener('input', () => {
                        that.nameError.style.display = 'none';
                    });

                    emailInput.addEventListener('input', () => {
                        that.emailError.style.display = 'none';
                    });

                    /**
                     *  regex  for validation
                     */

                    that.testFullName = () => {
                        return /^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/;
                    }
                    that.testEmail = (email) =>{       
                        return  String(email).toLowerCase().match(/^(?!.*\.\.)[a-zA-Z0-9._%+-]+@(?!(?:[a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com\.)+com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/);                       
                    }
                    that.testPhone = () => {
                        return /^(?:\+92|92|0)3[0-9]{2}[0-9]{7}$/                         
                    }
                    this.allowedExtensions = () =>{
                        return  /(\.pdf|\.doc|\.docx)$/i;
                    } 

                    /**
                     * regex functions for validations
                     */
                                        
                    if ((that.name.trim() === '') && !that.testFullName().test(that.name)) {
                        that.nameError.style.display = 'block';
                        return;
                    }
                    
                    if (!that.testEmail(that.email)) {
                        that.emailError.style.display = 'block'
                        return;
                    }
                                        
                    if (!that.testPhone().test(that.phone)) {
                        return  that.phoneError.style.display = 'block'                            
                    }

                    if (that.careerResume.files.length > 0) {                                           
                        grecaptcha.ready(async() => {
                            try {
                                that.token = await grecaptcha.execute('6LditXgqAAAAAEKGhplTplVF2Gkadv7SQTcGVCUv',{action: 'contact'});
                                if (that.token) {
                                    document.getElementById('careers-recaptcha-Response').value = that.token;
                                    that.file = that.careerResume.files[0];                                             
                                    if (!this.allowedExtensions().test(that.file.name)) {                              
                                        return  console.log("Please upload only PDF or Word documents (.pdf, .doc, .docx).")
                                    }
                                                               
                                    that.formData = new FormData();
                                    that.formData.append("name", that.name);
                                    that.formData.append("email", that.email);
                                    that.formData.append("phone", that.phone);
                                    // that.formData.append("department_position",that.department_position)
                                    that.formData.append("department_position",'emptyString')
                                    that.formData.append("resume", that.file);
                                    that.formData.append("recaptchaResponse",that.token)      
                                    fetch('https://faysalexchange.digitzmedia.com/dashboard/public/api/career',{
                                        method: 'POST',
                                        body: that.formData
                                    })
                                    .then(response => response.json())
                                    .then(afterResponse => {
                                        console.log("Success:", afterResponse);
                                        if (afterResponse.status) {
                                            if (this.resumeFileInput) {
                                                this.resumeFileInput.setAttribute('placeholder','Upload Resume')    
                                            }
                                            that.afterSendCareerSuccess.style.display = 'block'
    
                                        }else{
                                            that.afterSendCareerWarning.style.display = 'block'; 
                                        }                  
                                        event.target.reset();
                                        this.createResumeInput()
                                    })
                                    .catch(error => {
                                        console.error("Error:", error);
                                        that.afterSendCareerWarning.style.display = 'block';
                                        event.target.reset();
                                    });
                                } else {
                                    console.log("reCAPTCHA verification failed.")                                
                                }
                            } catch (error) {
                                console.error("Error with reCAPTCHA:", error)                              
                            }
                        })
                    } else {
                        console.log("No file selected.");
                        that.resumeError.style.display = 'block'                        
                    }
                }
                
            createAnimateLinks(){
                let that = this
                that.links = document.querySelectorAll(".about__scroll__nav ul li a[href^='#']")
                that.links.forEach((link)=> {
                    link.addEventListener("click",function(e) {
                        that.hash = this.hash    
                        e.preventDefault()    
                        that.target = document.querySelector(that.hash)
                        if (that.target) {
                            window.scrollTo({
                                top: that.target.offsetTop,
                                behavior: "smooth"
                            })
                            setTimeout(() =>{
                                window.location.hash = that.hash
                            },100) 
                        }
                    })
                })
                /**
                * news & event hover dropdown  
                */
                that.productServices = document.querySelector('.products__and__services');

                if (that.productServices) {
                    let that = this
                    that.productTarget  = that.productServices.querySelector('.dropdown-menu')
                    that.productServices.addEventListener('mouseover',()=>{
                        if (!that.productTarget.classList.contains('Product__custom__dropdownEnable')) {
                            that.productTarget.classList.add('Product__custom__dropdownEnable');
                            that.productTarget.classList.remove('Product__custom__dropdownDisable');
                            that.productTarget.classList.add('show');
                        }                      
                    })
                    that.productServices.addEventListener('mouseout',()=>{
                        if (that.productTarget.classList.contains('Product__custom__dropdownEnable')) {
                            that.productTarget.classList.remove('Product__custom__dropdownEnable');
                            that.productTarget.classList.add('Product__custom__dropdownDisable');                                                 
                        } 
                    })
                    that.productServices.addEventListener('animationend',() => {
                            if (that.productTarget.classList.contains('Product__custom__dropdownDisable')) {
                                that.productTarget.classList.remove('show');
                            }
                        },
                        {once: false}                           
                    )                    
                }

                that.newsEvents = document.querySelector('.news__and__events');

                if (that.newsEvents) {
                    let that = this
                    that.newsTarget  = that.newsEvents.querySelector('.dropdown-menu')
                    that.newsEvents.addEventListener('mouseover',()=>{
                        if (!that.newsTarget.classList.contains('news__custom__dropdownEnable')) {
                            that.newsTarget.classList.add('news__custom__dropdownEnable');
                            that.newsTarget.classList.remove('news__custom__dropdownDisable');
                            that.newsTarget.classList.add('show');
                        }                      
                    })
                    that.newsEvents.addEventListener('mouseout',()=>{
                        if (that.newsTarget.classList.contains('news__custom__dropdownEnable')) {
                            that.newsTarget.classList.remove('news__custom__dropdownEnable');
                            that.newsTarget.classList.add('news__custom__dropdownDisable');                                                 
                        } 
                    })

                    that.newsEvents.addEventListener('animationend',() => {
                            if (that.newsTarget.classList.contains('news__custom__dropdownDisable')) {
                                that.newsTarget.classList.remove('show');
                            }
                        },
                        {once: false}                           
                    )                    
                }
               
                
                that.buttons = [...document.querySelectorAll(".about__scroll__nav button")]
                    if (that.buttons[0]){
                        that.buttons[0].classList.add("default__button")         
                        that.buttons.forEach((button)=> {
                            button.addEventListener("click", function(){
                                that.buttons.forEach(function(btn){
                                btn.classList.remove("default__button")
                            })  
                            this.classList.add("default__button")
                        })
                    })                
                }
            }

        
            /**
             * switcher
             */

            createSwitcher() {

                this.switcher = [...document.querySelectorAll('.switcher__wrapper  .round')]
                this.switcherText = [...document.querySelectorAll('.switcher__text')]
                this.blendModeText = [...document.querySelectorAll('.blend__mode')] 
                this.blendModeCard = [...document.querySelectorAll('.custom__card__section__2')] 
                this.blendModeWhite = [...document.querySelectorAll('.blend__mode__white')]
                this.customCardsSectionFiver = [...document.querySelectorAll('.custom__card__section__5')]
                this.blendInput = [...document.querySelectorAll('.custom__input')] 
                this.siteLogo = [...document.querySelectorAll('.navbar-brand img')]
                this.sectionSevenBG = document.querySelector('.section-7')
                this.darkModeSlides = [...document.querySelectorAll('.slider__card')]
                this.customCardBorder = document.querySelector('.custom__card')
                this.sliderPagination = document.querySelector('.swiper-pagination .swiper-pagination-bullet-active .swiper-pagination-bullet-active')
                this.aboutSectionOne =  document.querySelector('.about-section-1')
                this.hexagonCalc = document.querySelector('.hexagon')   
                this.contactSectionSix =  document.querySelector('.get-in-touch-section-2')
                this.careerSetionFour = document.querySelector('.careers-section-4')
                this.careerSectionSix = document.querySelector('.careers-section-6')
                this.faqSectionOne = document.querySelector('.faqs-section-2')
                this.faqCards = document.querySelectorAll('.card')
                this.annoucmentSectionTwo = document.querySelector('.announcement-section-2')
                this.annoucmentFields = document.querySelectorAll('.annoucement__fields .row')
                this.newsEventSectionTwo = document.querySelector('.news-and-event-section-2')
                this.newsEventCards = document.querySelectorAll('.event__card')
                this.offCanvasContainer  = document.querySelector('.offcanvas__container')
                this.sectionThree = document.querySelector('.section-3')
                this.faqSectionZero = document.querySelector('.faqs-section-0')
                this.body = document.querySelector('body')
                this.digitzTag = document.querySelector('.digitz__tagline')
                this.allBreadCrums = document.querySelectorAll('.breadcrumb li')
                this.darkModeSection = [...document.querySelectorAll('.dark__mode__section')]
                this.forWhite  = [...document.querySelectorAll('.for__white')]
                

            }

            /**
             * onclick switcher
             */

            onClickSwitch(callFrom) {
                let that = this

                if (callFrom !== 'onPageLoad') {
                    localStorage.setItem('dark_mode', !JSON.parse(localStorage.getItem('dark_mode')))
                }

                this.blueText  = [...document.querySelectorAll('.blue__text')]                
                this.branchLocation = document.querySelectorAll('.branch')
                this.whiteText = document.querySelectorAll('.white__text')
                this.currencyRatesIcon = document.querySelectorAll('select2-container--default .select2-selection--single .select2-selection__arrow b')
                this.branchLocationNumbers = [...document.querySelectorAll('.branches__wrapper .location__contact__numbers')]
                this.allPara = [...document.querySelectorAll('.footer__white')]
                this.PageTitles = [...document.querySelectorAll('h2')]
                this.hexagonTabs = [...document.querySelectorAll('.nav-pills li a')]
                that.calcText = [...document.querySelectorAll('.select2-selection__rendered span')]
                that.hexagonActive = document.querySelectorAll('.nav-pills .nav-item .nav-link')
                that.tabsInput = document.querySelectorAll('.tab-content input')

                if (this.allPara) {
                    this.allPara.forEach(t=>{
                        t.style.color = '#aab3cd'
                    })
                }

                if (this.forWhite ) {
                    this.forWhite.forEach(t =>{
                        t.style.color = '#ffffff'
                    })
                } 
                
                
                if (that.darkModeSlides) {
                    that.darkModeSlides.forEach((element)=>{
                        if(!(element.classList.contains('slider__background__black'))){
                            element.classList.add('slider__background__black')
                        }
                    })
                }
                this.currencyRatesIcon.forEach((element)=>{element.style.borderTop = '#ffffff'})
                that.rule = CSSRulePlugin.getRule(".hexagon::before")
                that.swithRule = CSSRulePlugin.getRule('.slider.round:before')
                that.inputRule = CSSRulePlugin.getRule('.tab-content input::-webkit-input-placeholder')

                if (JSON.parse(localStorage.getItem('dark_mode'))) {

                    if (this.darkModeSection) {
                        this.darkModeSection.forEach(m =>{
                            m.style.backgroundColor = '#0f1217'
                        })
                    }
           
                    if (this.PageTitles) {
                        this.PageTitles.forEach(t=>{
                            t.style.color = '#ffffff'
                        })
                    }
                    
                    if (this.blueText) {                         
                        this.blueText.forEach((t)=>{
                            t.style.color = '#AAB3CD'
                        })
                    }

                    if (this.branchLocationNumbers) {
                        this.branchLocationNumbers.forEach(t=>{
                            t.style.color = '#ffffff'
                        })
                    }
                               
                    if (this.whiteText) {
                        this.whiteText.forEach((element)=>{
                            if(!(element.classList.contains('dark-mode-white-text'))){
                                element.classList.add('dark-mode-white-text')
                            }
                        })
                    }
                     
                    if (this.faqSectionZero) {
                        this.faqSectionZero.style.backgroundColor = '#0F1217'
                    }

                
                    GSAP.set('.slider',{backgroundColor:'#007278'})
                    GSAP.set(that.swithRule,{cssRule:{transform:'translateX(26px)'}})

                    if (this.sectionThree) {
                        this.sectionThree.style.background = `linear-gradient(0deg, rgba(15, 18, 23, 0.00) 0%, #0F1217 50%), url(${pattern}) #0F1217 -144.872px -201px / 120.121% 122.584% repeat `
                    }

                    if (that.hexagonActive) {
                        that.hexagonActive.forEach(t=>{
                            t.style.borderColor = '#ffffff'
                        })
                    }

                    if (this.hexagonTabs) {
                        this.hexagonTabs.forEach(t=>{
                            t.style.color = '#ffffff'
                        })
                    }
                
                    if (this.allBreadCrums ) {
                        this.allBreadCrums.forEach(t=>{
                            t.style.color = '#ffffff'
                        })
                    }

                    if (this.digitzTag) {
                    this.digitzTag.style.color = '#ffffff' 
                    }   

                    if (this.offCanvasContainer ) {
                        this.offCanvasContainer.style.backgroundColor = '#0F1217' 
                    }
                    if (this.annoucmentSectionTwo) {
                        this.annoucmentSectionTwo.style.backgroundColor = '#0F1217'
                    }
                    if (this.faqCards) {
                        this.faqCards.forEach(c=>{
                            c.style.backgroundColor = '#212121'
                        })
                    }
                    if (this.annoucmentFields) {
                        this.annoucmentFields.forEach(c=>{
                            c.style.backgroundColor = '#212121'
                        })
                    }                    
                    if (this.newsEventCards) {
                        this.newsEventCards.forEach(c=>{
                            c.style.backgroundColor = '#212121'
                        })
                    }
                    if (this.newsEventSectionTwo) {
                        this.newsEventSectionTwo.style.backgroundColor = '#0F1217'

                    }
                    this.branchLocation.forEach((element)=>{element.style.backgroundColor = '#1D1F25'})
                    
                    if (this.faqSectionOne) {
                        this.faqSectionOne.style.backgroundColor = '#0F1217'
                    }                    
                    if (window.innerWidth <= 640) {
                        if (this.hexagonCalc) {
                            this.hexagonCalc.style.backgroundColor = '#1d1f25';
                        } 
                    }
                    if (this.careerSetionFour && this.careerSectionSix) {
                        this.careerSetionFour.style.background = `linear-gradient(180deg, rgba(248, 248, 248, 0) -11.43%, #0F1217 32.66%)`
                        this.careerSectionSix.style.background = `linear-gradient(180deg, rgba(248, 248, 248, 0) -11.43%, #0F1217 32.66%)` 
                    }
                    if (this.contactSectionSix) {
                        this.contactSectionSix.style.backgroundColor = '#0F1217'
                    }

                    if (this.siteLogo) {
                        this.siteLogo.forEach(e=>{e.src = `${LogoWhite}` })
                    }

                    if (that.body) {
                        that.body.classList.add('defaultIn')
                        that.body.classList.remove('defaultOut')
                    }
                 
                    if (that.switcherText) {
                        that.switcherText.forEach(element => element.textContent = 'Dark Mode')
                    }
                    if (that.blendModeText) {
                        that.blendModeText.forEach(text=> text.style.color = '#AAB3CD')
                    }
                    if (that.blendModeWhite) {
                        that.blendModeWhite.forEach(white=> white.style.color = '#ffffff')
                    }
                    if(that.blendModeCard){
                        that.blendModeCard.forEach((card)=> {
                            card.style.backgroundColor = '#393D47'
                            card.style.border = `${1}px solid #393D47`
                        })
                    }
                    if (this.customCardsSectionFiver) {
                        this.customCardsSectionFiver.forEach(c=>{
                            c.style.backgroundColor = '#393D47'
                        })
                    }
                    
                    if (that.blendInput) {
                        that.blendInput.forEach(input=> input.style.backgroundColor = '#1D1F25')
                    }

                    if (that.sectionSevenBG) {
                        that.sectionSevenBG.style.backgroundImage = `url(${sectionSevenDark})`
                    }

                    if (this.aboutSectionOne) {
                        this.aboutSectionOne.style.backgroundImage = `url(${sectionSevenDark})`
                    }

                    if(this.currencyRatesIcon){
                        this.currencyRatesIcon.forEach((element)=>{element.style.borderTop = '#E1E1E1'})
                    }
                 
                    if (that.containsBG) {
                        that.containsBG.forEach((element,index)=>{
                            if ((index % 2 === 0) === false) {
                                element.classList.remove('exchange-coloured')   
                                element.classList.add('column-in-dark-1')
                            }else{
                                element.classList.add('column-in-dark-2')
                            }
                        })
                    }

                    if (that.rule) {
                        GSAP.set(that.rule,{cssRule:{backgroundImage:`url(${hexagon_black})`}})                        
                    }

                    if (that.inputRule) {
                        GSAP.set(that.inputRule,{cssRule:{color:'#AAB3CD'}})                         
                    }
                    if (that.calcText) {
                        that.calcText.forEach(t=>{
                            t.style.color = '#ffffff'
                        })
                    }

                } else {

                    if (this.forWhite) {
                        this.forWhite.forEach(t =>{ t.style.color = ''})
                    }                     
                    if (this.darkModeSection) {
                        this.darkModeSection.forEach(m =>{ m.style.backgroundColor = ''})                        
                    }
                    if (that.inputRule) {
                        GSAP.set(that.inputRule,{cssRule:{color:'#737779'}})                         
                    }
                    if (this.hexagonTabs) {
                        this.hexagonTabs.forEach(t=>{ t.style.color = ''})
                    }                
                    if (that.calcText) {
                        that.calcText.forEach(t=>{ t.style.color = '#737779'})
                    }
                    if (that.hexagonActive) {
                        that.hexagonActive.forEach(t=>{ t.style.borderColor = ''})
                    }
                    if (this.allBreadCrums ) {
                        this.allBreadCrums.forEach(t=>{t.style.color = ''})
                    }                 
                    if (this.PageTitles) {
                        this.PageTitles.forEach(t=>{ t.style.color = ''})
                    }                   
                    if (this.digitzTag) {
                        this.digitzTag.style.color = '#737779' 
                    }       
                    if (this.allPara) {
                        this.allPara.forEach(t=>{ t.style.color = '#737779'})
                    }
                    if (this.branchLocationNumbers) {
                        this.branchLocationNumbers.forEach(t=>{
                            t.style.color = '#2D2968'
                        })
                    }
                    if (this.faqSectionZero) {
                        this.faqSectionZero.style.backgroundColor = '#f8f8f8'
                    }

                    if (this.sectionThree) {
                        this.sectionThree.style.background = `linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%), url(${pattern}) lightgray -144.872px -201px / 120.121% 122.584% repeat`
                    }
                    GSAP.set('.slider',{backgroundColor:'#cccccc'})

                    if (that.swithRule) {
                        GSAP.set(that.swithRule,{cssRule:{transform:'translateX(0px)'}})

                    }
                                     
                    if (this.whiteText) {
                        this.whiteText.forEach((element)=>{
                            if((element.classList.contains('dark-mode-white-text'))){
                                element.classList.remove('dark-mode-white-text')
                            }
                        })
                    }        
                    if (that.darkModeSlides) {
                        that.darkModeSlides.forEach((element)=>{
                            if((element.classList.contains('slider__background__black'))){
                                element.classList.remove('slider__background__black')
                            }
                        })
                    }                    
                    if (this.blueText) {                         
                        this.blueText.forEach((t)=>{ t.style.color = '#737779'})
                    }
                    if (this.branchLocation) {
                        this.branchLocation.forEach((element)=>{element.style.backgroundColor = '#ffffff'})
                    }

                    if (this.contactSectionSix) {
                        this.contactSectionSix.style.backgroundColor = '#F8F8F8'
                    }

                    if (this.offCanvasContainer ) {
                        this.offCanvasContainer.style.backgroundColor = '#ffffff' 
                    }

                    if (this.customCardsSectionFiver) {
                        this.customCardsSectionFiver.forEach(c=>{
                            c.style.backgroundColor = '#F5F5F5'
                        })
                    }
                    if (this.careerSetionFour && this.careerSectionSix) {
                        this.careerSetionFour.style.background = `linear-gradient(180deg, rgba(248, 248, 248, 0) -11.43%, #F8F8F8 32.66%)`
                        this.careerSectionSix.style.background = `linear-gradient(180deg, rgba(248, 248, 248, 0) -11.43%, #F8F8F8 32.66%)`
                    }
                    
                    if (this.siteLogo) { this.siteLogo.forEach( e =>{ e.src = `${defautLogo}`})}
                    if (that.body) {
                        that.body.classList.remove('defaultIn')
                        that.body.classList.add('defaultOut')
                    }
                    if (that.switcherText) {
                        that.switcherText.forEach(element => element.textContent = 'Light Mode')
                    }
                    if (that.blendModeText) {
                        that.blendModeText.forEach(text=>text.style.color = '#737779')
                    }
                    if (that.blendModeWhite) {
                        that.blendModeWhite.forEach(white=>white.style.color = '#2B2C6E')
                    }
                    if (that.blendModeCard) {
                        that.blendModeCard.forEach((card)=> {
                            card.style.backgroundColor = '#f5f5f5'
                            card.style.border = `${1}px solid #eee`
                            card.style.borderBotttom  = `$${1}px solid #eee`;                           
                        })
                    }
                    if( that.blendInput){
                        that.blendInput.forEach(input=> input.style.backgroundColor = '#ffffff')
                    }

                    if (that.sectionSevenBG) {
                        that.sectionSevenBG.style.backgroundImage = `url(${sectionSixWhite})`
                    }
                    if (this.aboutSectionOne) {
                        this.aboutSectionOne.style.backgroundImage = `url(${sectionSixWhite})`
                    }
                    if (that.containsBG) {
                        that.containsBG.forEach((element,index) => {
                            if ((index % 2 === 0) === false) {
                                element.classList.remove('column-in-dark-1')
                                element.classList.add('exchange-coloured')
                            }else {
                                element.classList.remove('column-in-dark-2')
                            }
                        })
                    }
                    if (window.innerWidth <= 640) {
                        if (this.hexagonCalc) {
                            this.hexagonCalc.style.backgroundColor = '#ffffff';
                        } 
                    }
                    if (this.faqSectionOne) {
                        this.faqSectionOne.style.backgroundColor ='#F8F8F8'
                    }
                    if (this.annoucmentSectionTwo) {
                        this.annoucmentSectionTwo.style.backgroundColor = '#F8F8F8'
                    }
                    if (this.faqCards) {
                        this.faqCards.forEach(c=>{c.style.backgroundColor = '#ffffff'})
                    }
                    if (this.newsEventCards) {
                        this.newsEventCards.forEach(c=>{c.style.backgroundColor = '#F8F8F8'})
                    }                    
                    if (this.annoucmentFields) {
                        this.annoucmentFields.forEach(c => {c.style.backgroundColor = '#ffffff'})
                    }
                    if (this.newsEventSectionTwo) {
                        this.newsEventSectionTwo.style.backgroundColor = '#f8f8f8'
                    }
                    if (that.rule) {
                        GSAP.set(that.rule,{cssRule:{backgroundImage:`url(${hexagon_white})`}})
                    }
                }
            }

            /**
             * swiper slider
             */

            createSwiper() {

                this.sliders = [...document.querySelectorAll('.swiper')]
                this.sliderClasses = [
                    '.announcement-slider',
                    '.managment-team-slider',
                    '.board-of-team-slider',
                    '.currency-slider',
                    '.section__2__slider__wrapper__2',
                    '.section__2__slider__wrapper__1',
                ]
                this.sliderPagination = [
                    '.swiper-pagination',
                    '.swiper-pagination',
                    '.swiper-pagination',
                ]

                this.pagination = [
                    '.custom-scrollbar-1',
                    '.custom-scrollbar-2',
                ]

                /**
                 * for loop for getting all swiper slider around the website
                 */
                for (let index = 0; index < this.sliderClasses.length; index++) {   

                    new Swiper(this.sliderClasses[index], {
                        modules: [Pagination, Autoplay,Scrollbar],
                        direction: 'horizontal',
                        loop: (index === 5 || index === 4 ) ? false : true,
                        speed: (index === 3 ) ? 8000 : 600,
                        autoplay: (index === 0) ? { delay: 2000 } : (index === 3 ) ? { delay:0 } : false,
                        slidesPerView: (index === 0) ? 1 : (index === 3) ? 7 : (index === 2 || index === 1) ? 5 : 4,
                        spaceBetween: (index === 3) ? 20 :(index === 5 || index === 4) ? 20 : 10,
                        freeMode: (index === 3) ? true : false,
                        pagination: {
                            el: (index === 0) ? this.sliderPagination[0] : (index === 1) ? this.sliderPagination[1] : (index === 2) ? this.sliderPagination[2] : (index === 3) ? this.sliderPagination[3] : false,
                            paginationClickable: true,
                            type: 'bullets',
                            clickable: true,
                            bulletClass: 'custom__bullet__swiper', 
                            bulletActiveClass: 'custom-bullet-active',                   
                        },                          
                        scrollbar:{
                            el:(index === 5) ? this.pagination[0] : (index === 4 ) ? this.pagination[1] : null
                        },                           
                        breakpoints: {
                            1024: {
                                slidesPerView: (index === 2) ? 3 : (index === 1) ? 4 : (index === 3) ? 4 : (index === 5) ? 4 : (index === 4) ? 3 : 1
                            },
                            576: {
                                slidesPerView: (index === 0) ? 1 : (index === 3) ? 3 : 4,
                            },
                            768: {
                                slidesPerView: (index === 0) ? 1 : (index === 3) ? 4 : 4,
                            },
                            992: {
                                slidesPerView: (index === 0) ? 1 : (index === 3) ? 4 : 4,
                            },
                            1536: {
                                slidesPerView: (index === 0) ? 1 : (index === 3) ? 7 :(index === 5) ? 4 : 4,
                            },
                            1280: {
                                slidesPerView: (index === 0) ? 1 : (index === 2) ? 4 : (index === 1) ? 4 : (index === 3) ? 5 : 4,
                            },
                            1200: {
                                slidesPerView: (index === 0) ? 1 : (index === 3) ? 6 : 4,
                            },
                            1440: {
                                slidesPerView: (index === 0) ? 1 : (index === 3) ? 6  : (index === 4) ? 4 : 4,
                            },
                            480: {
                                slidesPerView: (index === 3) ? 2 :(index === 4) ? 1 : (index === 5 || index === 4) ? Number(1.2) : 1,
                                spaceBetween: (index === 0) ? 200 : 10,
                            },
                            320: {
                                slidesPerView :(index === 3) ? 2 : (index === 4) ? 1  : (index === 5 || index === 4) ? Number(1.2) : 1,
                                spaceBetween: (index === 0) ? 200 :(index === 4) ? 0 : (index === 5) ? 20 : 10,
                            },
                            640: {
                                slidesPerView: (index === 3) ? 2 : (index === 1 || index === 2) ? 1 : (index === 4) ? 3 : 4,
                            },
                            1920: {
                                slidesPerView: (index === 3) ? 8 : (index === 1 || index === 2) ? 4 : (index === 4) ? 4 : (index === 5 ) ?  4 : 1,
                                loop: (index === 5 || index === 4 ) ? false : true                                                                                       
                            },
                                                   
                        }
                    })
                }
            }

            /**
             * Events
             */

            addEventsListeners() {
                this.switcher.forEach((element)=>{
                    element.addEventListener('click',() => {this.onClickSwitch(null)})
                })            
                if (this.onContactSubmitForm) {
                    this.onContactSubmitForm.addEventListener('submit',this.onContactSubmit.bind(this))
                }
                if ( this.onCareerSubmitForm) {
                    this.onCareerSubmitForm.addEventListener('submit',this.onCareerSubmit.bind(this))
                }
                if (this.resumeFileInput) {
                    this.resumeFileInput.addEventListener('change',()=>{                        
                        this.onResumeInput()
                    })
                }

                this.humberger.addEventListener('click',this.onHamburgerClick.bind(this))
            }    

            onOffCanvasEvents(){ 
               
                if (this.offCanvasEnable) { 
                    if (!this.onOffCanvasEnable().isActive()) {
                        this.offCanvasEnable.addEventListener('click',()=>{
                            if (this.isTrue) return;
                            this.onOffCanvasEnable().play()
                        })
                    }
                } 
                                            
                if (this.offCanvasDisable) {
                    this.offCanvasDisable.addEventListener('click',()=>{
                        this.isTrue = false
                        this.onOffCanvasDisable().play()    
                    })

                }
            }
        }



        /**
         * class App Init
         */




        new App()